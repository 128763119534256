/* @ngInject */
export default function LikertScaleQuestionService(

  _,
  $translate,
  $timeout,
) {
  const TYPES = {
    DISCRETE: 'Discrete',
    CONTINUOUS: 'Continuous',
  };

  const LikertScaleQuestion = function (attributes) {
    const _this = this;
    const base = {
      response: null,
      templateUrl: 'quizzes/templates/questions/nv-likert-scale-question.html',
      displayState: null,
    };

    let correctAnswer;
    let correctAnswerKey;
    let correctAnswerValues;

    /** Setting Up Basic Attributes * */
    _.extend(_this, base, attributes);

    preprocess();
    calculateAnswer();

    /** Public Functions * */
    _this.isUnAnswered = isUnAnswered;
    _this.responsePayload = responsePayload;
    _this.setResponse = setResponse;
    _this.resetResponse = resetResponse;
    _this.updateResponseOptions = updateResponseOptions;

    _this.getCorrectAnswer = function () {
      return correctAnswer;
    };

    _this.getCorrectAnswerKey = function () {
      return correctAnswerKey;
    };

    _this.getCorrectAnswerValues = function () {
      return correctAnswerValues;
    };

    _this.getResponse = function () {
      _this.sliderOptions.readOnly = true;
      return _this.currentResponse;
    };

    /** Private Functions * */
    function preprocess() {
      _this.sliderOptions = {
        floor: _this.leftLimit,
        ceil: _this.rightLimit,
        step: isDiscrete() ? 1 : 0.1,
        precision: isDiscrete() ? 0 : 1,
        hideLimitLabels: true,
      };

      if (_this.response == null) {
        resetResponse();
      }

      $timeout(() => {
        const inputElement = document.getElementById(`question_${_this.responseOptions[0].questionId}`);

        if (inputElement) {
          const handle = inputElement?.getElementsByClassName('rz-pointer rz-pointer-min');
          if (handle.length > 0) {
            // setting aria-describedBy attribute to handle element of likert scale
            handle[0].setAttribute('aria-describedBy', `question_feedback_${_this.responseOptions[0].questionId}`);
            handle[0].setAttribute('tabindex', '0');
          }
        }
      });
    }

    function calculateAnswer() {
      const [responseOption] = _this.responseOptions;

      if (responseOption.topOfRange == null || responseOption.bottomOfRange == null) {
        correctAnswer = '';
      } else {
        const topOfRange = parseFloat(responseOption.topOfRange);
        const bottomOfRange = parseFloat(responseOption.bottomOfRange);

        if (responseOption.optionContent === TYPES.DISCRETE) {
          const marginError = (topOfRange - bottomOfRange) / 2.0;
          correctAnswer = bottomOfRange + marginError;
        }

        if (responseOption.optionContent === TYPES.CONTINUOUS) {
          correctAnswerKey = 'QUIZZES.CORRECT_ANSWER_RANGE';
          correctAnswerValues = {
            bottomOfRange,
            topOfRange,
          };
        }
      }
    }

    function isDiscrete() {
      return _this.responseOptions[0].optionContent === TYPES.DISCRETE;
    }

    function isUnAnswered() {
      return false;
    }

    function responsePayload() {
      return _this.response;
    }

    function updateResponseOptions(question) {
      _this.responseOptions = question.responseOptions;
      calculateAnswer();
    }

    function setResponse(response) {
      _this.currentResponse = { ...response };
      // This calculates where to locate the popover horizontally based on the value selected on the slider. The resulting value is a % used by the left css property
      _this.currentResponse.popoverPosition = ((response.response - _this.sliderOptions.floor) / (_this.sliderOptions.ceil - _this.sliderOptions.floor)) * 100;
      _this.response = response.response;
    }

    function resetResponse() {
      _this.sliderOptions.readOnly = false;
      _this.response = (_this.leftLimit + _this.rightLimit) / 2;
    }
  };

  return LikertScaleQuestion;
}
